<template>
  <div class="content">
    <Header>
      <div class="wrap">
        <!-- <div class="title">
          <div class="name">Let's party together</div>
          <div class="info">Every Moment, Chill and Fun!</div>
        </div> -->
        <!-- <div class="headimg">
          <img src="../img/headimg.png" alt="">
        </div> -->
      </div>
    </Header>
    <div class="bg1" id="about">
      <div class="wrap wow animate__fadeInUp " data-wow-duration="0.5s" data-wow-delay="0s">
        <div class="aboutus">
          <div class="pic"><img src="../img/aboutus.png" alt=""></div>
          <div class="infotext">
            <div class="titlename" style="text-align: left;">Who Are We?</div>
            <p class="intro">We are the world's top entertainment & dating platform, providing innovative live broadcast and social products, allowing users to enjoy entertainment and social fun around the world. With the tenet of "customer first", we continue to innovate, improve the quality of products and services, and bring more surprises and fun to users
            </p>
            <div class="Technology">FLAT 20 WALPOLE HOUSE 126 WESTMINSTER BRIDGE ROAD LONDON</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bg2" id="products">
      <div class="wrap wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
        <div class="products">
          <div class="titlename" style="margin-bottom: 70px;">What you can do on Wafa?</div>
          <div class="info">
            <div class="productsitem">
              <div class="icon">
                <img class="productsimg" src="../img/pro_icon1.png" alt="">
                <p>Voice Chat Room</p>
              </div>
              <div class="infotext">Wafa Voice Chat RoomParty & Chat in your favourite room. Join group voice chat room
                or create your own live chat.</div>
            </div>
            <div class="productsitem">
              <div class="icon">
                <img class="productsimg" src="../img/pro_icon2.png" alt="">
                <p>Private chat room</p>
              </div>
              <div class="infotext">Private Converstation Start voice chat with friends to talk about your
                interests.Enjoy yourself! Wafa wish everyone in our community to showcase yourself, and stay connected
                in a free, friendship, and respect way.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="wrap wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s" id="contact">
        <div class="letsplay">
          <div class="titlename" style="margin-bottom: 55px;">Let's Play NOW</div>
          <div class="infoimg">
            <img src="../img/letsplay_img.png" alt="">
          </div>
        </div>
      </div>

    </div> -->
    <div class="bg4" id="contactus">
      <div class="wrap wow animate__fadeInUp " data-wow-duration="0.5s" data-wow-delay="0s">
        <div class="contu">
          <div class="titlename" style="margin-bottom: 55px;">Contact Us </div>
          <ContactUs></ContactUs>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import ContactUs from '@/components/ContactUs/ContactUs.vue'
export default {
  components: {
    ContactUs
  },
  watch: {
    "$route": {
      handler(val) {
        if (this.$route.query["about"]) {
          this.gotocontent("about")
        }
        if (this.$route.query["products"]) {
          // this.gotocontent("products")
        }
        if (this.$route.query["contactus"]) {
          this.gotocontent("contactus")
        }
      }
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    new this.$wow.WOW({
      live: true
    }).init()
    if (this.$route.query["about"]) {
      this.gotocontent("about")
    }
    if (this.$route.query["products"]) {
      // this.gotocontent("products")
    }
    if (this.$route.query["contactus"]) {
      this.gotocontent("contactus")
    }
  },
  methods: {
    slidepic() {
      const imgswiper = this.$refs.imgswiper
      console.log(imgswiper.scrollLeft);
      if (imgswiper.scrollLeft >= 1800) {
        imgswiper.scrollLeft = 1
      }
      if (imgswiper.scrollLeft <= 0) {
        imgswiper.scrollLeft = 1799
      }
      console.log(imgswiper.scrollLeft);
    },
    gotocontent(data) {
      document.getElementById(`${data}`).scrollIntoView()
      this.$router.push('/')
    }
  }

}
</script>

<style lang="scss" scoped src="./content.scss">

</style>