<template>
  <div class="tos">
    <Detail :deteil_title="'Terms of service'"></Detail>
    <div class="detailwrap detail_text">
<P>1.Introduction</P>
<P>Welcome to Yiha!</P>
<P>Your use of Yiha is subject to these Terms of Use (hereinafter "Terms"). Thank you for reviewing these Terms – we hope you enjoy using Yiha.</P>
<P>If you have any question about, or if you wish to send us any notice in relation to, these Terms, please contact us at 【contact@snarintel.com】.</P>
<P></P>
<P>1.1 Compliance with these Terms</P>
<P></P>
<P>These Terms apply to you if you are a user of Yiha anywhere in the world.</P>
<P></P>
<P>Please review these Terms and our policies and instructions to understand how you can and cannot use Yiha.</P>
<P></P>
<P>You must comply with these Terms in your use of Yiha and only use Yiha as permitted by applicable laws and regulations, wherever you may be when you use this software.</P>
<P></P>
<P>If you do not agree to these Terms, you may not use Yiha and must immediately terminate your use of this software and all services.</P>
<P></P>
<P>1.2 Contracting Entity</P>
<P></P>
<P>By using Yiha, you are agreeing to be bound by these Terms between you and Meet Tech Co., Ltd, (hereinafter "we", "our" and "us").</P>
<P></P>
<P>We may specify in certain of our Yiha service-specific terms that you are contracting with one of our affiliate companies or Service Providers (instead of us) in relation to your use of the relevant Yiha service or feature to which the relevant service-specific terms apply. Where this is the case, the relevant contracting entity will be identified in the relevant Yiha service-specific terms, and these Terms (and those relevant service-specific terms) will apply between you and that identified contracting entity in relation to your use of the relevant Yiha services or features.</P>
<P></P>
<P>1.3 Service Eligibility</P>
<P></P>
<P>The services of Yiha are not for anyone under the age of 【18】.</P>
<P></P>
<P>To use the services, you agree that: (i) you must be the “Minimum Age” (described below) or older; and (ii) you are not already restricted by us from using the services provided via Yiha.</P>
<P></P>
<P>We may change the Minimum Age from time to time based on our sole discretion. Where this is the case, the users of Yiha who were below the new Minimum Age when they started using the services under a previous</P>
<P></P>
<P>Terms of Use may continue to use them, as they have already reached the new Minimum Age since then. “Minimum Age” means 【18】 years old. However, if applicable law requires that you must be older in order to lawfully use Yiha without parental consent (including using of your personal data) then the Minimum Age is such older age.</P>
<P></P>
<P>If applicable law requires you to obtain the consent from your parent or guardian, then your parent or guardian must agree to these Terms (both for themselves and on your behalf) before you can use Yiha.</P>
<P></P>
<P>1.4 Other general terms in relation to these Terms</P>
<P></P>
<P>We may translate these Terms into multiple languages, and in the event there is any difference between the English version and any other language version of these Terms, the English version will apply (to the extent permitted by applicable laws and regulations).</P>
<P></P>
<P>1.Additional Terms and Policies</P>
<P></P>
<P>We offer a diverse range of services and features within Yiha, so in addition to these Terms, there may be certain additional terms and policies that may be applicable to your use of all or part of Yiha, as set out in this section and as otherwise notified to you from time to time. These additional Terms of Use and policies all form part of and are incorporated into these Terms.</P>
<P></P>
<P>2.1 Yiha policies</P>
<P></P>
<P>Besides these Terms, you must also comply with Yiha Privacy Policy which sets out how we collect, store and use your personal information in using Yiha.</P>
<P></P>
<P>2.2 Terms applicable to specific Yiha features</P>
<P></P>
<P>Some of our services and features may have additional terms specific to their use (as notified by us to you), and you must comply with such additional terms in your use of such services and features, as well as these Terms.</P>
<P></P>
<P>2.3 Inconsistencies</P>
<P></P>
<P>To the extent that any additional terms or policies are different from these Terms, the relevant additional terms or additional policies will apply, except that nothing in those additional terms or policies will (unless expressly indicated otherwise in those additional terms or policies) amend the following sections of these Terms:</P>
<P></P>
<P>• "Changes to these Terms and Yiha";</P>
<P></P>
<P>• "Our Intellectual Property Rights"; and</P>
<P></P>
<P>• "Liability for Yiha".</P>
<P></P>
<P>1.Changes to These Terms and Yiha</P>
<P></P>
<P>We may make changes to these Terms above from time to time, so please come back and review them. In addition, as Yiha and user experiences are constantly evolving, we may from time to time (and to the extent permitted by applicable laws and regulations) add, change or remove features from Yiha (including in relation to whether a service is free of charge or not), or suspend or terminate a service altogether.</P>
<P></P>
<P>Where we consider that any changes to these Terms or Yiha are reasonably material, we will (where reasonably practicable) notify you of such changes by posting them on our website, through our services or by sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to use Yiha after we make any change to these Terms or Yiha, with or without notice from us, you are agreeing to be bound by these revised Terms. If you object to any changes, you may close your account.</P>
<P></P>
<P>1.Your Account</P>
<P></P>
<P>You will need to create an account with us in order to access and use Yiha via (i) using your existing account opened at third party social media platforms (such as Facebook or Google) and (ii) registering and logging in as visitors. Under the circumstance you choose (i), we will have the right to access, collect and use your information relating to these third party accounts.</P>
<P></P>
<P>Any account that you open with us is personal to you and you are prohibited from gifting, lending, transferring or otherwise permitting any other person to access or use your account. Your account name, user ID and other identifiers you adopt within Yiha remains our property and we can disable, reclaim and reuse these once your account is terminated or deactivated for whatever reason by either you or us.</P>
<P></P>
<P>You are responsible for: (i) safeguarding your account details, including any password used to access your account and Yiha, and (ii) all use of Yiha under your account. You must promptly notify us at 【contact@snarintel.com】 if you know or suspect that your password or account has been compromised. We will regard all use of your account on Yiha as being by you, except where we have received and acknowledged your notification to us regarding your account/password being compromised. Yiha may offer methods for deactivating your service-specific account – please refer to Yiha for further guidance (if available) from time to time.</P>
<P></P>
<P>Please note that when you login as a visitor, there is always a risk of loss of both your account and any interests linked with the account, such as gold coins you prepaid.</P>
<P></P>
<P>2.Payments</P>
<P></P>
<P>You may, from time to time, make payments to us or third parties as part of your use of Yiha (including for the provision of Yiha or provision of certain additional features within Yiha). We may set out further terms applying to such payments (including in relation to refunds (if any), billing arrangements and any consequences of failing to make timely payments). You must comply with all such terms in relation to your payments to us. You agree that you are solely responsible for all fees and taxes associated with any such payments, and that pricing and availability of items and products are subject to change at any time.</P>
<P></P>
<P>SUBJECT TO MANDATORY APPLICABLE LAWS AND REGULATIONS OR AS OTHERWISE SPECIFIED BY US FOR A PARTICULAR ITEM OR PRODUCT WITHIN Yiha, IN NO CIRCUMSTANCES WILL WE BE REQUIRED TO PROVIDE A REFUND FOR ANY PAYMENTS MADE BY YOU TO US IN RELATION TO ANY ITEMS OR PRODUCTS WITHIN Yiha (WHETHER USED OR UNUSED).</P>
<P></P>
<P>We may process payments from you in Yiha via a third party service and you agree to comply with that relevant third party's terms and conditions in relation to the payment processing service, as further set out in the "Third Party Content and Services" section below.</P>
<P></P>
<P>Also, you agree that:</P>
<P></P>
<P>• your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates);</P>
<P></P>
<P>• we may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.</P>
<P></P>
<P>5.1Your Content</P>
<P></P>
<P>6.1 Posting your content</P>
<P></P>
<P>When you submit, upload, transmit or display any data, information, photograph or other content in connection with your use of Yiha (hereinafter "Your Content"), you understand and agree that:</P>
<P></P>
<P>1) you acknowledge and agree that you are solely responsible for any Content that you transmit or display through Yiha and we are not responsible to you or any third party in connection with any of Your Content;</P>
<P></P>
<P>2) you are prohibited from distributing via Yiha any content that may be considered objectionable, which includes, but is not limited to, (i) sexually explicit, pornographic, or obscene content; (ii) hate speech; (iii) violent or cruel content; (iv) spam, rumors, or other misleading information; (v) content that may mentally or physically hurt other people; (vi) content that may infringe intellectual property rights; (vii) any other content that does not comply with our Terms or the law.</P>
<P></P>
<P>3) we reserve the right to block or remove Your Content for any reason, including as is in our opinion appropriate or as required by applicable laws and regulations and we recommend that you keep a back-up copy of it at all times;</P>
<P></P>
<P>4) you must at all times ensure that: (i) you have the rights required to submit, transmit or display Your Content, and to grant us the rights as set out in these Terms; and (ii) Your Content (and our use of Your Content in accordance with these Terms) does not infringe or violate the rights of any person or otherwise contravene any applicable laws or regulations.</P>
<P></P>
<P>5) Your Content will be shared with other users of Yiha in many ways. For example, Your Content will be seen by other users of Yiha once you have uploaded Your Content to Yiha;</P>
<P></P>
<P>6) you are giving us and our affiliate companies the right to use Your Content (with no fees or charges payable by us to you) solely for the purposes of providing, promoting, developing and trying to improve Yiha and our other services, including new services that we may provide in the future.</P>
<P></P>
<P>6.2 Third parties</P>
<P></P>
<P>In addition, you agree that we and our affiliate companies (subject to these Terms, our Yiha Privacy Policy and applicable laws and regulations):</P>
<P></P>
<P>1) may copy, reproduce, host, store, process, adapt, modify, translate, perform, distribute and publish Your Content worldwide by all distribution methods, including those that are developed in the future;</P>
<P></P>
<P>2) may share Your Content with third parties that we work with to help provide, promote, develop and improve Yiha, but these third parties (other than our affiliate companies) will not make any separate use of Your Content for their own purposes (i.e. for any purposes that are not related to Yiha);</P>
<P></P>
<P>3) may use the name that you submit in connection with Your Content (whether that be your account name, real name or otherwise);</P>
<P></P>
<P>4) are allowed to retain and continue to use Your Content after you stop using Yiha – for example, where you have shared Your Content with other users of Yiha;</P>
<P></P>
<P>5) may be required to retain or disclose Your Content in order to: (i) comply with applicable laws or regulations; (ii) comply with a court order, subpoena or other legal process; or (iii) respond to a lawful request by a government authority, law enforcement agency or similar body (whether situated in your jurisdiction or elsewhere); and</P>
<P></P>
<P>6) may need to retain or disclose Your Content in order to enforce these Terms, protect our rights, property or safety, or the rights, property or safety of our affiliate companies or other users of Yiha.</P>
<P></P>
<P>6.3 Other information</P>
<P></P>
<P>(1) You understand that even if you seek to delete Your Content from Yiha, it may as a technical and administrative matter take some time or not be possible to achieve this – for example, we may not be able to prevent any third party from storing or using any of Your Content that you have made public via Yiha.</P>
<P></P>
<P>(2) Yiha is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in Yiha Privacy Policy.</P>
<P></P>
<P>Infringement of Rights</P>
<P></P>
<P>We may respond to notices of alleged infringement of rights (including infringement of intellectual property rights, defamation and infringement of other civil rights) and other claims and demands. If you have any such infringement-related concerns, please contact 【contact@snarintel.com】.</P>
<P></P>
<P>Third Party Content and Services</P>
<P></P>
<P>We are not responsible for and we do not endorse, support or guarantee the lawfulness, accuracy or reliability of any content submitted to, transmitted or displayed by or linked by Yiha, including content provided by users of Yiha or by our advertisers. You acknowledge and agree that by using Yiha, you may be exposed to content which is inaccurate, misleading, defamatory, offensive or unlawful. Any reliance on or use of any content on or accessible from Yiha by you is at your own risk. Your use of Yiha does not give you any rights in or to any content you may access or obtain in connection with your use of Yiha.</P>
<P></P>
<P>We also do not guarantee the quality, reliability or suitability of any third party services provided, made available, advertised or linked through Yiha and we will bear no responsibility for your use of or relationship with any such third parties or third party services.</P>
<P></P>
<P>We may review (but make no commitment to review) content or third party services made available through</P>
<P></P>
<P>Yiha to determine whether or not they comply with our policies, applicable laws and regulations or are otherwise objectionable. We may remove or refuse to make available or link to certain content or third party services if they infringe intellectual property rights, are obscene, defamatory or abusive, violate any rights or pose any risk to the security or performance of Yiha.</P>
<P></P>
<P>There may be, from time to time, third party content and services on Yiha that are subject to further terms – for examples, terms from the relevant third party that originally produced or created such content or service, or terms from the relevant third party in relation to promotional activities being held on Yiha. You agree to comply with any such further terms and conditions as notified to you in relation to your use of such third party content and services.</P>
<P></P>
<P>Advertising Content on Yiha</P>
<P></P>
<P>Yiha may include advertising or commercial content. You agree that we are allowed to integrate, display and otherwise communicate advertising or commercial content in Yiha.</P>
<P></P>
<P>Our Intellectual Property Rights</P>
<P></P>
<P>All intellectual property rights in or to Yiha (including any future updates, upgrades and new versions to Yiha), will continue to belong to us. Except as expressly provided in these Terms, you have no right to use our intellectual property rights. In particular, you have no right to use our trademarks or product names, logos, domain names or other distinctive brand features without our prior written consent. Any comments or suggestions you may provide regarding Yiha are entirely voluntary and we will be free to use these comments and suggestions at our discretion without any payment or other obligation to you.</P>
<P></P>
<P>You may not copy, modify, reverse compile, reverse engineer or extract source codes from Yiha, except to the extent that we may not prohibit you from doing so under applicable laws or regulations or you have our prior written consent to do so. Where applicable laws or regulations entitle you to reverse compile or extract source codes from Yiha, you will first contact us to request the information you need.</P>
<P></P>
<P>We may from time to time provide updates to Yiha. Such updates may occur automatically or manually. Please note that Yiha may not operate properly or at all if upgrades or new versions are not installed by you. We do not guarantee that we will make any updates available for any of Yiha, or that such updates will continue to support your device or system.</P>
<P></P>
<P>We may in our discretion provide technical support for Yiha (whether for free or for a fee). We provide technical support without any guarantee or warranty of any kind, and subject always to these Terms.</P>
<P></P>
<P>Use of Your Device by Yiha In order for us to provide Yiha to you, we may require access to and/or use of your relevant device (e.g. mobile phone, tablet or desktop computer) that you use to access Yiha – for example, we may need to use your device's processor and storage to complete the relevant Yiha software installation, or use your device's camera and microphone to provide video chat services to you. You agree to give us such access to and use of your device.</P>
<P></P>
<P>You hereby authorize us to collect and use the address books, contact lists, mail box, system application lists, device information, and unique device identifiers (IMEI, UDID, or other) contained in the Devices to which you have downloaded Yiha for purposes of your use of the services.</P>
<P></P>
<P>We will provide further information regarding how Yiha uses and accesses your device within Yiha or in another manner (e.g. via the relevant app store as part of the installation process for Yiha on your device). You understand that if you do not provide us with such right of use or access, we may not be able to provide Yiha to you.</P>
<P></P>
<P>Any Personal Information (as defined in Yiha Privacy Policy) that we use or access within your device will be treated in accordance with these Terms, including our Yiha Privacy Policy.</P>
<P></P>
<P>You may need an adequate internet connection in order to authentic your Yiha account or use Yiha. You may also be required to activate certain functionalities within Yiha in the manner described within Yiha. You may not be able to use certain functionalities within Yiha if you do not comply with such requirements.</P>
<P></P>
<P>Please note that we are not responsible for any third party charges you incur (including any charges from your internet and telecommunication services providers) in relation to or arising from your use of Yiha.</P>
<P></P>
<P>Do’s and Don’ts</P>
<P></P>
<P>12.1 You agree that you will:</P>
<P></P>
<P>a. comply with all applicable laws, including privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</P>
<P></P>
<P>b. strictly follow these Terms;</P>
<P></P>
<P>c. use Yiha in a good manner.</P>
<P></P>
<P>12.2 You agree that you won’t:</P>
<P></P>
<P>a. act in an unlawful or bad manner in connection with Yiha, including being dishonest, abusive or discriminatory;</P>
<P></P>
<P>b. post defamatory, obscene, shocking, hateful, threatening or otherwise inappropriate content or airing personal grievances or disputes;</P>
<P></P>
<P>c. create a profile for anyone other than yourself (a real person);</P>
<P></P>
<P>d. use or attempt to use another's account;</P>
<P></P>
<P>e. steal or attempt to steal another’s virtual property, such as virtual gifts;</P>
<P></P>
<P>f. harass, abuse or harm another person;</P>
<P></P>
<P>g. develop, support or use software, devices, scripts, robots, or any other means or processes (including crawlers, add-ons, or any other technology or manual work) to scrape Yiha or otherwise copy profiles and other data from Yiha;</P>
<P></P>
<P>h. bypass or circumvent any access controls or service use limits;</P>
<P></P>
<P>i. copy, use, disclose or distribute any information obtained from Yiha, whether directly or through third parties (such as search engines), without the consent of us;</P>
<P></P>
<P>j. use, disclose or distribute any data obtained in violation of these Terms;</P>
<P></P>
<P>k. disclose information that you do not have the consent to disclose (such as confidential information of others);</P>
<P></P>
<P>l. violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights;</P>
<P></P>
<P>m. violate the intellectual property or other rights of us;</P>
<P></P>
<P>n. post anything that contains software viruses, worms, or any other harmful code;</P>
<P></P>
<P>o. manipulate identifiers in order to disguise the origin of any message or post transmitted through Yiha;</P>
<P></P>
<P>p. create or operate a pyramid scheme, fraud or other similar practice;</P>
<P></P>
<P>q. reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for Yiha or any related technology that is not open source;</P>
<P></P>
<P>r. remove, cover or obscure any advertisement included in Yiha;</P>
<P></P>
<P>s. use bots or other automated methods to access Yiha, add or download contacts, send or redirect messages;</P>
<P></P>
<P>t. monitor Yiha’s availability, performance or functionality for any competitive purpose;</P>
<P></P>
<P>u. access Yiha except through the interfaces expressly provided by us, such as its mobile applications;</P>
<P></P>
<P>v. use Yiha for tasks that it is not intended for;</P>
<P></P>
<P>w. override any security feature of Yiha; and/or</P>
<P></P>
<P>x. interfere with the operation of, or place an unreasonable load on, Yiha (e.g., spam, denial of service attack, viruses, gaming algorithms).</P>
<P></P>
<P>Warranty and Disclaimer</P>
<P></P>
<P>We warrant to you that we will provide Yiha using reasonable care and skill.</P>
<P></P>
<P>APART FROM THIS WARRANTY, TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, Yiha IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND NEITHER US NOR ANY OF OUR AFFILIATE COMPANIES MAKE ANY REPRESENTATION OR WARRANTY OR GIVES ANY UNDERTAKING IN RELATION TO Yiha OR ANY CONTENT SUBMITTED, TRANSMITTED OR DISPLAYED BY Yiha, INCLUDING: (I) ANY REPRESENTATION, WARRANTY OR UNDERTAKING THAT Yiha WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE OR FREE FROM VIRUSES; (II) THAT Yiha WILL BE COMPATIBLE WITH YOUR DEVICE; OR (III) THAT Yiha WILL BE OF MERCHANTABLE QUALITY, FIT FOR A PARTICULAR PURPOSE OR NOT INFRINGE THE INTELLECTUAL PROPERTY RIGHTS OF ANY PERSON. TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, YOU WAIVE ANY AND ALL IMPLIED REPRESENTATIONS, WARRANTIES AND UNDERTAKINGS.</P>
<P></P>
<P>Liability for Yiha</P>
<P></P>
<P>TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, THE TOTAL AGGREGATE LIABILITY OF US AND OUR AFFILIATE COMPANIES FOR ALL CLAIMS IN CONNECTION WITH THESE TERMS, OR Yiha, ARISING OUT OF ANY CIRCUMSTANCES, WILL BE LIMITED TO THE AMOUNT YOU PAID US TO USE Yiha AND IN NO EVENT WILL IT EXCEED</P>
<P></P>
<P>50.</P>
<P></P>
<P>TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, IN NO EVENT WILL WE OR ANY OF OUR AFFILIATE COMPANIES BE LIABLE FOR ANY OF THE FOLLOWING:</P>
<P></P>
<P>IN CONNECTION WITH THESE TERMS OR Yiha, FOR ANY DAMAGES CAUSED BY: (I) ANY NATURAL DISASTER SUCH AS FLOODS, EARTHQUAKES OR EPIDEMICS; (II) ANY SOCIAL EVENT SUCH AS WARS, RIOTS OR GOVERNMENT ACTIONS; (III) ANY COMPUTER VIRUS, TROJAN HORSE OR OTHER DAMAGE CAUSED BY MALWARE OR HACKERS; (IV) ANY MALFUNCTION OR FAILURE OF OUR OR YOUR SOFTWARE, SYSTEM, HARDWARE OR CONNECTIVITY; (V) IMPROPER OR UNAUTHORIZED USE OF Yiha; (VI) YOUR USE OF Yiha IN BREACH OF THESE TERMS; OR (VII) ANY REASONS BEYOND OUR REASONABLE CONTROL OR PREDICTABILITY;</P>
<P></P>
<P>• FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES; AND/OR</P>
<P></P>
<P>• FOR ANY LOSS OF BUSINESS, REVENUES, PROFITS, GOODWILL, CONTENT OR DATA.</P>
<P></P>
<P>NOTWITHSTANDING ANY OTHER PROVISIONS OF THESE TERMS, NOTHING IN THESE TERMS LIMITS OR EXCLUDES ANY OF YOUR STATUTORY RIGHTS IN YOUR JURISDICTION (INCLUDING ANY RIGHTS UNDER APPLICABLE CONSUMER PROTECTION REGULATION), TO THE EXTENT THESE MAY NOT BE EXCLUDED OR WAIVED UNDER APPLICABLE LAWS AND REGULATIONS.</P>
<P></P>
<P>YOU AGREE THAT YOU INDEMNIFY US AND OUR AFFILIATE COMPANIES FROM AND AGAINST ANY CLAIM, SUIT, ACTION, DEMAND, DAMAGE, DEBT, LOSS, COST, EXPENSE (INCLUDING LITIGATION COSTS AND ATTORNEYS' FEES) AND LIABILITY ARISING FROM: (I) YOUR USE OF Yiha; OR (II) YOUR BREACH OF THESE TERMS.</P>
<P></P>
<P>No Liability for Third Parties</P>
<P></P>
<P>As set out in the "Third Party Content and Services" sections of these Terms, various third parties may provide certain content, services or software within Yiha.</P>
<P></P>
<P>THESE TERMS GOVERN THE RELATIONSHIP BETWEEN YOU AND US. YOUR DEALINGS WITH ALL THIRD PARTIES, INCLUDING THOSE FOUND THROUGH Yiha, ARE SOLELY BETWEEN YOU AND THE RELEVANT THIRD PARTY. SUBJECT TO MANDATORY APPLICABLE LAWS AND REGULATIONS, WE AND OUR AFFILIATE COMPANIES HAVE NO LIABILITY TO YOU IN RELATION TO ANY THIRD PARTIES, INCLUDING ANY CONTENT OR SERVICES PROVIDED BY SUCH THIRD PARTIES WITHIN Yiha.</P>
<P></P>
<P>Termination</P>
<P></P>
<P>These Terms will apply to your use of Yiha until your access to Yiha is terminated by either you or us.</P>
<P></P>
<P>We may suspend or terminate your access to your account or any or all of Yiha: (i) if we reasonably believe that you have breached these Terms; (ii) if your use of Yiha creates risk for us or for other users of Yiha, gives rise to a threat of potential third party claims against us or is potentially damaging to our reputation; (iii) if you fail to use Yiha for a prolonged period; or (iv) for any other reason in our sole and absolute discretion. Where reasonably practicable, we will give you notice of any suspension or termination.</P>
<P></P>
<P>Upon termination of your access to Yiha (in whole or in part), you will immediately and permanently delete all copies of Yiha to which the termination relates and you will immediately cease accessing and using Yiha.</P>
<P></P>
<P>Following termination of these Terms, we will only retain and use Your Content in accordance with these Terms (in particular, the Yiha Privacy Policy). Where we suspend or terminate all or part of Yiha, or where your access to Yiha is terminated by you or us, we do not guarantee that we will be able to return any of Your Content back to you and we may permanently delete Your Content without notice to you at any time after termination. Please ensure that you regularly back up Your Content.</P>
<P></P>
<P>General</P>
<P></P>
<P>These Terms are the entire agreement between you and us in relation to Yiha. You agree that you will have no claim against us for any statement which is not explicitly set out in these Terms.</P>
<P></P>
<P>The words "include" and "including" are to be construed without limitation. The invalidity of any provision of these Terms (or parts of any provision) will not affect the validity or enforceability of any other provision (or the remaining parts of that provision). If a court holds that we cannot enforce any part of these Terms as drafted, we may replace those terms with similar terms to the extent enforceable under applicable laws and regulations, without changing the remaining terms of these Terms. No delay in enforcing any provision of these Terms will be construed to be a waiver of any rights under that provision. Any rights and obligations under these Terms which by their nature should survive, including any obligations in relation to the liability of, or indemnities (if any) given by, the respective parties, will remain in effect after termination or expiration of these Terms.</P>
<P></P>
<P>No person other than you and us will have any right to enforce these Terms against any person, and you may not delegate, assign or transfer these Terms or any rights or obligations under these Terms without our prior consent. We may freely assign, transfer or sub-contract these Terms or our rights and obligations under these Terms, in whole or in part, without your prior consent or prior notice to you.</P>
<P></P>
<P>Governing Law and Dispute Resolution</P>
<P></P>
<P>Except to the extent that: (i) any applicable additional terms incorporated into these Terms provide differently, or (ii) the applicable laws and regulations of your jurisdiction mandate otherwise (for example, you may have statutory rights in your jurisdiction in relation to bringing or defending claims in a local court (including small claims court)):</P>
<P></P>
<P>• these Terms and any dispute or claim arising out of or in connection with these Terms will be governed by the law of the People’s Republic of China (exclusive of the Hong Kong Special Administrative Region, the Macau Special Administrative Region and Taiwan);</P>
<P></P>
<P>• any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating to, or in connection with these Terms, including their existence, validity, interpretation, performance, breach or termination, will be referred to and finally resolved by arbitration administered by Beijing Arbitration Commission in accordance with its rules of arbitration in effect at the time of applying for arbitration. The seat of the arbitration will be Beijing. The arbitration proceedings will be conducted in Chinese. The arbitral award is final and binding upon both you and us.</P>
  </div>
  <div class="detailunderline"></div>
  <!-- <Footer></Footer> -->
</div></template>


<script>
import Detail from '@/components/Detail/Detail.vue'
import Footer from '@/components/Footer/Footer.vue'
export default {
  components: {
    Detail,
    Footer
  }

}
</script>

<style></style>