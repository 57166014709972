<template>
  <div class="footer">
    <div class="wrap">
      <div class="footernav">
        <div class="f_logo">
          <!-- <img src="../img/logo2.png" alt="" /> -->
          <p class="title">INTERPLAY INNOVATIONS LTD</p>
          <p class="address">
            FLAT 20 WALPOLE HOUSE 126 WESTMINSTER BRIDGE ROAD LONDON
          </p>
        </div>
        <div class="r">
          <Nav class="rnav" :hrefList="hrefList"></Nav>
        </div>
      </div>
      <div class="footerinfo">
        <p>© 2024 All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue';
export default {
  data() {
    return {
      hrefList: [], //['About Us', 'Products', 'Contact Us']
    };
  },
  components: {
    Nav,
  },
};
</script>

<style lang="scss" scoped src="./footer.scss">
</style>